import React from "react";
import Layout from "components/layout";
import ContactPage from "./paths/contact_page";
import FsAnalytics from "components/fs_analytics";

const Contact = () => {
  return (
    <Layout>
      {/* Finstead Analytics */}
      <FsAnalytics />
      <ContactPage />
    </Layout>
  );
};
export default Contact;
